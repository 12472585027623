import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  colspan: "8",
  class: "text-center"
}
const _hoisted_2 = { class: "font-weight-normal" }
const _hoisted_3 = { class: "font-weight-normal" }
const _hoisted_4 = { class: "font-weight-normal" }
const _hoisted_5 = { class: "font-weight-normal" }
const _hoisted_6 = { class: "dropdown" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = { class: "dropdown-menu" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_admin = _resolveDirective("admin")!

  return (_openBlock(), _createElementBlock("tr", null, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("td", _hoisted_1, _cache[2] || (_cache[2] = [
          _createElementVNode("span", {
            class: "font-weight-bolder",
            style: {"font-size":"1.5em"}
          }, "Loading ...", -1)
        ])))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("td", null, [
            _createVNode(_component_router_link, {
              to: `/users/${_ctx.dt.user_id}`,
              class: "font-weight-bold"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.dt.user_id), 1)
              ]),
              _: 1
            }, 8, ["to"])
          ]),
          _createElementVNode("td", null, [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.dt.User.username), 1)
          ]),
          _createElementVNode("td", null, [
            _createVNode(_component_router_link, {
              to: `/users/${_ctx.dt.user_id}`,
              class: "font-weight-normal text-primary"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.dt.User.email), 1)
              ]),
              _: 1
            }, 8, ["to"])
          ]),
          _createElementVNode("td", null, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.dt.balance), 1)
          ]),
          _createElementVNode("td", null, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.dt.is_banned ? "YES" : "NO"), 1)
          ]),
          _createElementVNode("td", null, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.dt.User.clearance), 1)
          ]),
          _createElementVNode("td", null, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("button", {
                class: "btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0",
                type: "button",
                id: "dropdownMenuButton",
                "data-bs-toggle": "dropdown",
                "aria-expanded": "false",
                disabled: _ctx.loading
              }, _cache[3] || (_cache[3] = [
                _createElementVNode("span", { class: "icon icon-sm" }, [
                  _createElementVNode("span", { class: "fas fa-ellipsis-h icon-dark" })
                ], -1),
                _createElementVNode("span", { class: "sr-only" }, "Toggle Dropdown", -1)
              ]), 8, _hoisted_7),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_router_link, {
                  to: `/users/${_ctx.dt.user_id}`,
                  class: "dropdown-item text-dark"
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createElementVNode("span", { class: "fas fa-eye mr-1" }, null, -1),
                    _createTextVNode(" See Details ")
                  ])),
                  _: 1
                }, 8, ["to"]),
                _withDirectives((_openBlock(), _createElementBlock("a", {
                  href: "#",
                  class: _normalizeClass(["dropdown-item text-danger", _ctx.dt.is_banned ? 'text-danger' : 'text-dark']),
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (
                  _ctx.RequestConfirmation(
                    `Are you sure you want to ${
                      _ctx.dt.is_banned ? 'unban' : 'ban'
                    } this user?`,
                    _ctx.ToggleBan
                  )
                ))
                }, [
                  (_ctx.dt.is_banned)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _cache[5] || (_cache[5] = _createElementVNode("span", { class: "fas fa-times mr-1" }, null, -1)),
                        _cache[6] || (_cache[6] = _createTextVNode(" Lift Ban "))
                      ], 64))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _cache[7] || (_cache[7] = _createElementVNode("span", { class: "fas fa-check mr-1" }, null, -1)),
                        _cache[8] || (_cache[8] = _createTextVNode(" Ban User "))
                      ], 64))
                ], 2)), [
                  [_directive_admin, 100]
                ]),
                _withDirectives((_openBlock(), _createElementBlock("a", {
                  class: _normalizeClass(["dropdown-item", _ctx.dt.clearance < 3 ? 'text-danger' : 'text-dark']),
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (
                  _ctx.RequestConfirmation(
                    _ctx.dt.clearance < 3
                      ? 'Do you really want to give this user admin access'
                      : 'Are you sure to remove admin access from this user',
                    _ctx.ToggleAdmin
                  )
                ))
                }, [
                  _cache[9] || (_cache[9] = _createElementVNode("span", { class: "fas fa-user-cog mr-1" }, null, -1)),
                  (_ctx.dt.clearance < 3)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_9, "Make Admin"))
                    : (_openBlock(), _createElementBlock("span", _hoisted_10, "Remove Admin"))
                ], 2)), [
                  [_directive_admin, 100]
                ])
              ])
            ])
          ])
        ], 64))
  ]))
}