import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row justify-content-md-center mt-4" }
const _hoisted_2 = { class: "col-12 mb-4" }
const _hoisted_3 = { class: "col-12 mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Metrics = _resolveComponent("Metrics")!
  const _component_UserWithBalance = _resolveComponent("UserWithBalance")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Metrics)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_UserWithBalance)
      ])
    ])
  ]))
}