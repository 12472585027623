import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-between align-items-center py-1" }
const _hoisted_2 = { class: "d-flex align-content-center" }
const _hoisted_3 = {
  key: 0,
  class: "table-settings mb-1"
}
const _hoisted_4 = { class: "row align-items-center justify-content-between" }
const _hoisted_5 = { class: "col-12 pl-md-0" }
const _hoisted_6 = { class: "d-flex justify-content-end align-items-center py-1" }
const _hoisted_7 = { class: "nav nav-tabs mb-2" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "text-right mx-2" }
const _hoisted_11 = { class: "d-lg-flex flex-row justify-content-end align-items-center py-1" }
const _hoisted_12 = { class: "fw-bolder text-info border border-r ms-3 p-3" }
const _hoisted_13 = { class: "card card-body border-light shadow-sm table-wrapper table-responsive pt-0" }
const _hoisted_14 = { class: "table table-hover min-h-400" }
const _hoisted_15 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CountSelector = _resolveComponent("CountSelector")!
  const _component_DataFilterSelector = _resolveComponent("DataFilterSelector")!
  const _component_UserRow = _resolveComponent("UserRow")!
  const _component_Pagination = _resolveComponent("Pagination")!
  const _component_HourglassLoader = _resolveComponent("HourglassLoader")!

  return (_openBlock(), _createBlock(_component_HourglassLoader, { loading: _ctx.loading }, {
    default: _withCtx(() => [
      _createElementVNode("section", null, [
        _createElementVNode("div", _hoisted_1, [
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "d-block" }, [
            _createElementVNode("h2", { class: "h4" }, "Users with Balance")
          ], -1)),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", null, [
              _createElementVNode("button", {
                type: "button",
                class: "btn btn-sm btn-outline-primary",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.RefreshData && _ctx.RefreshData(...args)))
              }, "Reload")
            ]),
            (_ctx.data?.meta)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("div", _hoisted_6, [
                        _createVNode(_component_CountSelector, {
                          class: "me-2",
                          modelValue: _ctx.data.meta.per_page,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.data.meta.per_page) = $event)),
                          onUpdated: _ctx.RefreshData
                        }, null, 8, ["modelValue", "onUpdated"]),
                        _createVNode(_component_DataFilterSelector, {
                          modelValue: _ctx.type,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.type) = $event)),
                          filters: _ctx.filters,
                          onUpdated: _ctx.RefreshData
                        }, null, 8, ["modelValue", "filters", "onUpdated"])
                      ])
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("ul", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currencies, (currency) => {
              return (_openBlock(), _createElementBlock("li", {
                class: "nav-item m-2",
                key: currency.id
              }, [
                _createElementVNode("button", {
                  class: _normalizeClass(['btn btn-secondary', _ctx.currentTab == currency.id ? 'bg-primary text-white' : '']),
                  onClick: ($event: any) => (_ctx.selectTab(currency.id))
                }, _toDisplayString(currency?.code), 11, _hoisted_8)
              ]))
            }), 128))
          ])
        ]),
        (_ctx.data?.totalUserBalance)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _cache[5] || (_cache[5] = _createElementVNode("p", { class: "text-gray text-right" }, "Total Balance", -1)),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("h5", _hoisted_12, _toDisplayString(new Intl.NumberFormat(`en-${_ctx.data?.totalUserBalance?.currencyCode == 'NGN' ? 'NG' : _ctx.data?.totalUserBalance?.currencyCode == 'GHS' ? 'GH' : _ctx.data?.totalUserBalance?.currencyCode == 'USD' ? 'US' : ''}`, { style: 'currency', currency: `${_ctx.data.totalUserBalance?.currencyCode}`, minimumFractionDigits: 4,
              maximumFractionDigits: 4, }).format(_ctx.data?.totalUserBalance?.totalBalance )), 1)
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("table", _hoisted_14, [
            _cache[6] || (_cache[6] = _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", null, "#"),
                _createElementVNode("th", null, "Username"),
                _createElementVNode("th", null, "Email"),
                _createElementVNode("th", null, "Balance"),
                _createElementVNode("th", null, "Is Banned"),
                _createElementVNode("th", null, "Clearance"),
                _createElementVNode("th")
              ])
            ], -1)),
            (_ctx.data)
              ? (_openBlock(), _createElementBlock("tbody", _hoisted_15, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.userLists, (dt, index) => {
                    return (_openBlock(), _createBlock(_component_UserRow, {
                      key: index,
                      dt: dt
                    }, null, 8, ["dt"]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]),
          (_ctx.data)
            ? (_openBlock(), _createBlock(_component_Pagination, {
                key: 0,
                modelValue: _ctx.data.meta.page,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.data.meta.page) = $event)),
                currentPage: _ctx.data.meta.page,
                totalRecord: _ctx.data.meta.count,
                dataCount: _ctx.data.meta.per_page,
                onUpdated: _ctx.RefreshData
              }, null, 8, ["modelValue", "currentPage", "totalRecord", "dataCount", "onUpdated"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["loading"]))
}